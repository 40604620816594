import { Action, createReducer, on } from '@ngrx/store';
import { ProjectsState } from '@customer-apps/shared/interfaces';
import { ProjectBasicGetRecordsList } from '@customer-apps/api-planning-projects';
import { ProjectsActions } from './projects.actions';
import { defaultProjectsPageIndex, defaultProjectsPageSize } from './projects.constants';
import { basicProjectsToProjectList } from '../../shared/utils';

export const initialProjectsState: ProjectsState = {
    projects: [],
    projectFormValue: undefined,
    queryParams: {
        pageIndex: defaultProjectsPageIndex,
        pageSize: defaultProjectsPageSize
    },
    pagination: {
        totalProjectsCount: 0,
        totalPagesCount: 0
    },
    loaded: false,
    loading: false
};

const reducer = createReducer(
    initialProjectsState,
    on(ProjectsActions.getProjects, (state, { queryParams }) => ({
        ...state,
        queryParams,
        loading: true
    })),
    on(ProjectsActions.getProjectsSuccess, (state, { projects, totalProjectsCount, totalPagesCount }) => ({
        ...state,
        loading: false,
        loaded: true,
        projects: basicProjectsToProjectList(projects),
        pagination: {
            totalProjectsCount,
            totalPagesCount
        }
    })),
    on(ProjectsActions.getProjectsError, state => ({
        ...state,
        loading: false,
        loaded: true
    })),
    on(ProjectsActions.buildingValidateAddress, (state, { projectFormValue }) => ({
        ...state,
        projectFormValue
    })),
    on(ProjectsActions.updatePagination, (state, { params }) => ({
        ...state,
        queryParams: {
            ...state.queryParams,
            pageIndex: params.pageIndex,
            pageSize: params.pageSize
        }
    })),
    on(ProjectsActions.filterProjects, (state, { params }) => ({
        ...state,
        queryParams: {
            ...state.queryParams,
            filter: params.filter,
            lastChangeDateFrom: params.lastChangeDateFrom,
            lastChangeDateTo: params.lastChangeDateTo,
            projectStatus: params.projectStatus,
            allProjects: params.allProjects
        }
    })),
    on(ProjectsActions.orderProjects, (state, { params }) => ({
        ...state,
        queryParams: {
            ...state.queryParams,
            orderBy: params.orderBy,
            orderType: params.orderType
        }
    })),
    on(ProjectsActions.clearProjects, state => ({
        ...state,
        queryParams: {
            pageIndex: 0,
            pageSize: state.queryParams?.pageSize
        }
    }))
);

export function projectsReducer(state: ProjectsState | undefined, action: Action) {
    return reducer(state, action);
}
