import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { catchError, first, map, switchMap, tap, withLatestFrom, delay } from 'rxjs/operators';
import { ProjectsControllerService, BuildingsControllerService, CreateProjectRequestPost } from '@customer-apps/api-planning-projects';
import { ApiErrors, ResponseSummaryActionType, RoutePaths, RouterLinks } from '@customer-apps/shared/enums';
import { LodashService, SnackbarService } from '@customer-apps/shared/services';
import { ErrorResponse, ProjectFormValue, RequestProjectsQueryParams } from '@customer-apps/shared/interfaces';
import { ConfirmService } from '@customer-apps/shared/ui';
import { ApiErrorsMessageDictionary, MAX_PROJECTS_LIMIT } from '@customer-apps/shared/utils';
import { CustomSnackbarMessageComponent } from '../../shared/components/custom-snackbar-message/custom-snackbar-message.component';
import { GlobalSpinnerService } from '../../core/components/global-spinner/global-spinner.service';
import { EnvironmentService } from '../../services';
import { SettingsStore } from '../settings/settings.store';
import { ProjectActions } from '../project/project.actions';
import { defaultProjectsPageSize } from './projects.constants';
import { ProjectsActions } from './projects.actions';
import { ProjectsStore } from './projects.store';
import { ProjectAccessActions } from '../project-access/project-access.actions';
import { ProjectStore } from '../project/project.store';

@Injectable()
export class ProjectsEffects {
    constructor(
        private actions$: Actions,
        private translateService: TranslateService,
        private projectsControllerService: ProjectsControllerService,
        private buildingsControllerService: BuildingsControllerService,
        private snackbarService: SnackbarService,
        private globalSpinnerService: GlobalSpinnerService,
        private translate: TranslateService,
        private settingsStore: SettingsStore,
        private projectsStore: ProjectsStore,
        private projectStore: ProjectStore,
        private environmentService: EnvironmentService,
        private confirmService: ConfirmService,
        private router: Router
    ) {}

    public handleRouteQueryParams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.handleRouteQueryParams),
            withLatestFrom(this.projectStore.project$, (action, project) => ({
                queryParams: action.queryParams,
                projectId: project?.id!
            })),
            switchMap(({ queryParams, projectId }) => {
                if (!queryParams.page) {
                    const pageSize =
                        queryParams.pageSize && this.isPositiveNumber(queryParams?.pageSize)
                            ? +queryParams.pageSize
                            : defaultProjectsPageSize;
                    return [
                        ProjectActions.resetProject({ projectId }),
                        ProjectsActions.getProjects({
                            queryParams: {
                                ...queryParams,
                                pageIndex: 0,
                                pageSize
                            }
                        })
                    ];
                } else {
                    const page = queryParams.page && this.isPositiveNumber(queryParams?.page) ? +queryParams.page : 1;
                    const pageSize =
                        queryParams.pageSize && this.isPositiveNumber(queryParams?.pageSize)
                            ? +queryParams.pageSize
                            : defaultProjectsPageSize;
                    return [
                        ProjectActions.resetProject({ projectId }),
                        ProjectsActions.getProjects({
                            queryParams: {
                                ...LodashService.omit(queryParams, 'page'),
                                pageIndex: page - 1,
                                pageSize
                            }
                        }),
                        ProjectsActions.validateRouteQueryParams({ queryParams })
                    ];
                }
            })
        )
    );

    public getProjects$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.getProjects),
            withLatestFrom(this.settingsStore.countryCode$, this.projectsStore.state$, (action, countryCode, state) => ({
                previousQueryParams: state.previousQueryParams,
                currentQueryParams: action.queryParams,
                queryParams: {
                    ...action.queryParams,
                    countryCode: countryCode as 'DE' | 'BE' | 'AT' | 'NL',
                    includeArchivedProjects: action.queryParams.includeArchivedProjects || !this.environmentService.production
                }
            })),
            switchMap(({ queryParams }) =>
                this.projectsControllerService.projectsControllerGetProjects(queryParams).pipe(
                    map(response => {
                        if (response.totalProjectsCount > MAX_PROJECTS_LIMIT) {
                            const message = this.translateService.instant('COMMON.ERRORS.MAXIMUM_PROJECTS_LIMIT_REACHED');
                            const actionButton = this.translateService.instant('COMMON.BUTTONS.GOT_IT');
                            this.snackbarService.showInfo(message, {
                                duration: 8000,
                                action: actionButton
                            });
                        }
                        return ProjectsActions.getProjectsSuccess(response);
                    }),
                    catchError(() => {
                        const message = this.translateService.instant('COMMON.ERRORS.SOMETHING_WRONG');
                        const actionButton = this.translateService.instant('COMMON.BUTTONS.GOT_IT');
                        this.snackbarService.showInfo(message, {
                            duration: 5000,
                            action: actionButton
                        });
                        return of(ProjectsActions.getProjectsError());
                    })
                )
            )
        )
    );

    public deleteProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.deleteProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.DELETING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.DELETING_PROJECT'
                });
            }),
            switchMap(({ projectId }) =>
                this.projectsControllerService.projectsControllerDeleteProject({ id: projectId }).pipe(
                    map(() => ProjectsActions.deleteProjectSuccess()),
                    catchError(() => {
                        const message = this.translateService.instant('COMMON.ERRORS.DELETE_PROJECT');
                        this.snackbarService.showInfo(message);
                        return EMPTY;
                    })
                )
            )
        )
    );

    public deleteProjectSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.deleteProjectSuccess),
            withLatestFrom(this.projectsStore.state$, (_action, state) => ({ ...state.queryParams })),
            tap(action => {
                const pageSize = action.pageSize! && action.pageSize! !== defaultProjectsPageSize ? action.pageSize : null;
                this.router.navigate([RoutePaths.Projects], {
                    replaceUrl: true,
                    queryParams: { page: null, pageSize },
                    queryParamsHandling: 'merge'
                });
            }),
            switchMap(action => {
                if (action.pageIndex === 0) {
                    return of(
                        ProjectsActions.getProjects({
                            queryParams: {
                                ...action
                            }
                        })
                    );
                }
                return EMPTY;
            })
        )
    );

    public duplicateProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.duplicateProject),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.DUPLICATING_PROJECT',
                    loadingSubtext: 'LOADER.SUBTEXT.DUPLICATING_PROJECT'
                });
            }),
            switchMap(({ projectId, projectName, numberOfPersons }) =>
                this.projectsControllerService
                    .projectsControllerDuplicateProject({
                        id: projectId,
                        body: { projectName, numberOfPersons, applicationVersion: this.environmentService.version }
                    })
                    .pipe(
                        map(response => ProjectsActions.duplicateProjectSuccess({ response, projectName })),
                        catchError(error => this.handleApiErrors(error.error as ErrorResponse))
                    )
            )
        )
    );

    public duplicateProjectSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.duplicateProjectSuccess),
            tap(action => {
                const actionsSummary = action.response.actionsSummary;
                const hasEnergyPricesSetToDefault = actionsSummary?.find(
                    item => item.actionType === ResponseSummaryActionType.EnergyPricesSetToDefaults
                );
                const energyPricesPhraseKey = hasEnergyPricesSetToDefault
                    ? 'SNACKBAR.PROJECT_DUPLICATION.ENERGY_PRICES_SET_TO_DEFAULT'
                    : 'SNACKBAR.PROJECT_DUPLICATION.ENERGY_PRICES';

                const energyPricesMessage = this.translate.instant(energyPricesPhraseKey);
                const isCustomNumberOfPersons = actionsSummary?.find(
                    item => item.actionType === ResponseSummaryActionType.CustomNumberOfPersons
                );
                const basicDataPhraseKey = isCustomNumberOfPersons
                    ? 'SNACKBAR.PROJECT_DUPLICATION.BASIC_DATA'
                    : 'SNACKBAR.PROJECT_DUPLICATION.BASIC_DATA_WITH_NUMBER_OF_RESIDENTS';
                const basicDataMessage = this.translate.instant(basicDataPhraseKey);
                const message = this.translate.instant('SNACKBAR.PROJECT_DUPLICATION.MESSAGE', {
                    basicDataMessage,
                    energyPricesMessage,
                    projectName: action.projectName
                });
                const duration = 7000;
                const actionButton = this.translateService.instant('COMMON.BUTTONS.GOT_IT');
                this.snackbarService.showCustomInfo(CustomSnackbarMessageComponent, { data: { message, action: actionButton }, duration });
            }),
            switchMap(action => of(ProjectsActions.goToProjectDetails({ projectId: action.response.id! })))
        )
    );

    public buildingValidateAddress$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.buildingValidateAddress),
            tap(() => {
                this.globalSpinnerService.next({
                    isShown: true,
                    loadingText: 'LOADER.TEXT.VALIDATING_BUILDING_ADDRESS',
                    loadingSubtext: 'LOADER.SUBTEXT.VALIDATING_BUILDING_ADDRESS'
                });
            }),
            map(({ projectFormValue }) => {
                const [postalCode, city] = projectFormValue?.location?.postalCodeCity?.split(', ') || ['', ''];
                return {
                    buildingType: projectFormValue.buildingType!,
                    city,
                    countryCode: projectFormValue.location?.countryCode!,
                    houseNumber: projectFormValue.location?.houseNumber!,
                    postalCode,
                    streetName: projectFormValue.location?.streetName,
                    projectType: projectFormValue.projectType!
                };
            }),
            switchMap(body =>
                this.buildingsControllerService.buildingsControllerFindBuilding({ body }).pipe(
                    map(response => ProjectsActions.buildingValidateAddressSuccess(response)),
                    catchError(() => {
                        const message = this.translateService.instant('COMMON.ERRORS.SOMETHING_WRONG');
                        const actionButton = this.translateService.instant('COMMON.BUTTONS.GOT_IT');
                        this.snackbarService.showInfo(message, {
                            duration: 5000,
                            action: actionButton
                        });
                        return EMPTY;
                    })
                )
            )
        )
    );

    public buildingValidateAddressSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.buildingValidateAddressSuccess),
            withLatestFrom(this.projectsStore.projectFormValue$, (action, projectFormValue) => ({
                ...action,
                projectFormValue
            })),
            switchMap(payload => {
                const createNewProject$ = this.buildCreateProjectRequest(payload.projectFormValue!).pipe(
                    delay(100),
                    tap(() => {
                        this.globalSpinnerService.next({
                            isShown: true,
                            loadingText: 'LOADER.TEXT.SAVING_PROJECT',
                            loadingSubtext: 'LOADER.SUBTEXT.SAVING_PROJECT'
                        });
                    }),
                    switchMap(request => of(ProjectActions.createProject({ request })))
                );
                if (payload.projectId) {
                    return this.confirmService
                        .showDialog({
                            title: this.translateService.instant('CONFIRM.DUPLICATE_PROJECT.TITLE'),
                            content: this.translateService.instant('CONFIRM.DUPLICATE_PROJECT.CONTENT'),
                            confirmBtn: this.translateService.instant('COMMON.BUTTONS.DUPLICATE'),
                            cancelBtn: this.translateService.instant('COMMON.BUTTONS.NEW_PROJECT'),
                            uuid: payload.projectId
                        })
                        .pipe(
                            switchMap(output => {
                                if (output?.confirm) {
                                    return of(
                                        ProjectsActions.duplicateProject({
                                            projectId: payload.projectId!,
                                            projectName: payload.projectFormValue!.projectName!,
                                            numberOfPersons: payload.projectFormValue!.residentQuantity!
                                        })
                                    );
                                } else if (output?.cancel) {
                                    return createNewProject$;
                                }
                                return EMPTY;
                            })
                        );
                }
                return createNewProject$;
            })
        )
    );

    public getProjectsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectsActions.getProjectsSuccess),
            withLatestFrom(this.projectsStore.state$, (action, state) => ({
                response: action,
                pageIndex: state.queryParams?.pageIndex,
                pageSize: state.queryParams?.pageSize
            })),
            tap(payload => {
                /**
                 * If the page index or page size in the response is different from the one in the state,
                 * because user has set page number in url query params which is bigger then maximum one.
                 */
                if (payload.response.pageIndex !== payload.pageIndex || payload.response.pageSize !== payload.pageSize) {
                    const page = payload.response.pageIndex ? payload.response.pageIndex + 1 : null;
                    this.router.navigate([RoutePaths.Projects], {
                        replaceUrl: true,
                        queryParams: { page, pageSize: null },
                        queryParamsHandling: 'merge'
                    });
                }
            }),
            switchMap(payload =>
                of(
                    ProjectsActions.updatePagination({
                        params: { pageIndex: payload.response.pageIndex, pageSize: payload.response.pageSize }
                    })
                )
            )
        )
    );

    public validateRouteQueryParams$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectsActions.validateRouteQueryParams),
                tap(({ queryParams }) => {
                    if (!isNaN(+queryParams?.page!) && !isNaN(+queryParams?.pageSize!)) {
                        return;
                    }

                    this.router.navigate([RoutePaths.Projects], {
                        replaceUrl: true,
                        queryParams: {
                            ...queryParams,
                            page: this.isPositiveNumber(queryParams?.page) ? queryParams.page : null,
                            pageSize: this.isPositiveNumber(queryParams?.pageSize) ? queryParams.pageSize : null
                        },
                        queryParamsHandling: 'merge'
                    });
                })
            ),
        {
            dispatch: false
        }
    );

    public clearProjects$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectsActions.clearProjects),
                tap(() => {
                    this.router.navigate([RoutePaths.Projects], {
                        queryParams: { page: null, filter: null, projectStatus: null, lastChangeDateFrom: null, lastChangeDateTo: null },
                        queryParamsHandling: 'merge'
                    });
                })
            ),
        { dispatch: false }
    );

    public filterProjects$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectsActions.filterProjects),
                map(action => action.params),
                tap(params => {
                    this.router.navigate([RoutePaths.Projects], {
                        queryParams: {
                            filter: params.filter || null,
                            lastChangeDateFrom: params.lastChangeDateFrom || null,
                            lastChangeDateTo: params.lastChangeDateTo || null,
                            projectStatus: params.projectStatus || null,
                            allProjects: params.allProjects || null,
                            page: null
                        },
                        queryParamsHandling: 'merge'
                    });
                })
            ),
        { dispatch: false }
    );

    public orderProjects$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectsActions.orderProjects),
                map(action => action.params),
                tap(params => {
                    this.router.navigate([RoutePaths.Projects], {
                        queryParams: { orderBy: params.orderBy || null, orderType: params.orderType || null, page: null },
                        queryParamsHandling: 'merge'
                    });
                })
            ),
        { dispatch: false }
    );

    public pageProjects$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectsActions.pageProjects),
                map(action => action.params),
                tap(params => {
                    this.router.navigate([RoutePaths.Projects], {
                        queryParams: { page: params.pageIndex ? params.pageIndex + 1 : null },
                        queryParamsHandling: 'merge'
                    });
                })
            ),
        { dispatch: false }
    );

    public goToProjectDetails$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectsActions.goToProjectDetails),
                tap(({ projectId }) => {
                    this.router.navigate([`${RouterLinks.Project}${projectId}`]);
                })
            ),
        { dispatch: false }
    );

    public backToProjects$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(ProjectsActions.backToProjects),
                withLatestFrom(this.projectsStore.state$, (_action, state) => ({
                    ...state.queryParams
                })),
                tap(params => {
                    const queryParams = {
                        page: params.pageIndex! > 0 ? params.pageIndex! + 1 : null,
                        pageSize: params.pageSize! && params.pageSize! !== defaultProjectsPageSize ? params.pageSize : null,
                        filter: params.filter || null,
                        projectStatus: params.projectStatus || null,
                        lastChangeDateFrom: params.lastChangeDateFrom || null,
                        lastChangeDateTo: params.lastChangeDateTo || null,
                        allProjects: params.allProjects || null,
                        orderType: params.orderType || null,
                        orderBy: params.orderBy || null
                    };
                    this.router.navigate([RoutePaths.Projects], {
                        queryParams
                    });
                })
            ),
        {
            dispatch: false
        }
    );

    public resetProject$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProjectActions.resetProject),
            switchMap(({ projectId }) => [
                ProjectAccessActions.stopVerifyProjectLock(),
                ProjectAccessActions.unlockProject({ id: projectId })
            ])
        )
    );

    private handleApiErrors(error: ErrorResponse): Observable<any> {
        this.globalSpinnerService.reset();
        const messageKey = ApiErrorsMessageDictionary[error.message as ApiErrors];
        if (error.status === 422 && messageKey) {
            const message = this.translate.instant(messageKey);
            const action = this.translate.instant('COMMON.BUTTONS.GOT_IT');
            this.snackbarService.showInfo(message, {
                duration: 0,
                action
            });
        }
        return EMPTY;
    }

    private isPositiveNumber(value: string | undefined): boolean {
        return !isNaN(+value!) && +value! > 0;
    }

    private buildCreateProjectRequest(value: ProjectFormValue): Observable<CreateProjectRequestPost> {
        const countryCode$ = this.settingsStore.countryCode$.pipe(first());
        const languageCode$ = this.settingsStore.languageCode$.pipe(first());
        const [postalCode, city] = value.location?.postalCodeCity ? value.location.postalCodeCity.split(', ') : [];

        return forkJoin([countryCode$, languageCode$]).pipe(
            map(([countryCode, languageCode]) => {
                const request = {
                    address: {
                        streetName: value.location?.streetName,
                        houseNumber: value.location?.houseNumber!,
                        postalCode,
                        city,
                        countryCode: value.location?.countryCode!
                    },
                    buildingType: value.buildingType,
                    numberOfPersons: value.residentQuantity,
                    projectName: value.projectName,
                    projectType: value.projectType,
                    variantName: this.translateService.instant('COMMON.VARIANT_NAMES.VARIANT_1'),
                    countryCode: countryCode!,
                    languageCode: languageCode!,
                    applicationVersion: this.environmentService.version
                } as CreateProjectRequestPost;

                if (value.partnerNumber) {
                    request.partnerNumber = value.partnerNumber;
                }

                return request;
            })
        );
    }
}
